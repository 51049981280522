.ViewExcludedMailboxFormSection {
    &__wrapper {
        display: flex;
        padding: 1.5rem 2rem 2rem;
        gap: 1.5rem;
        
        & > form > button {
            width: 20rem;
            align-self: flex-end;
        }
    }
}